import React from 'react'
import { Heading, Flex, Box, Container, Text, ListItem, UnorderedList } from '@chakra-ui/react'
import HookForm from '../components/form1b'
import Header from '../components/header'

export default function Home() {
        const Project = 'GP1001'
        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                {Project} Self Tape Submissions
                        </Heading>
                        <Container mb={8} padding={1}>
                                <Heading as="h2" fontSize="2xl" m="2" align="center" textTransform="uppercase">
                                        Casting for AAA Game
                                </Heading>
                                <Box>
                                        <Text mb={2}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quid igitur
                                                dubitamus in tota eius natura quaerere quid sit effectum? Satis est ad
                                                hoc responsum. Duo Reges: constructio interrete. <br />
                                                Facit enim ille duo seiuncta ultima bonorum, quae ut essent vera,
                                                coniungi debuerunt; Re mihi non aeque satisfacit, et quidem locis
                                                pluribus. Videmus igitur ut conquiescere ne infantes quidem possint.
                                                Recte, inquit, intellegis. Quam si explicavisset, non tam haesitaret.
                                                Quid est, quod ab ea absolvi et perfici debeat? <br />
                                                If this sounds of interest please fill in the form below.
                                        </Text>
                                </Box>
                        </Container>
                        <Flex as="main" width="full" align="center" flexDirection="column" justifyContent="center">
                                <HookForm project={Project} />
                        </Flex>
                </>
        )
}
