import React, { useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useForm } from 'react-hook-form'
import {
        FormErrorMessage,
        Box,
        FormControl,
        Text,
        Input,
        InputGroup,
        InputLeftAddon,
        Button,
        Flex,
        useDisclosure,
        FormLabel,
        Textarea,
        Tooltip,
} from '@chakra-ui/react'
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'

import Recaptcha from 'react-recaptcha'

import Upload from './upload1'
import SubmitModal from './modal'
import '../styles/test.css'

let recaptchaInstance
const executeCaptcha = e => {
        e.preventDefault()
        recaptchaInstance.execute()
}

export default function HookForm(props) {
        const childRef = useRef()
        const { project } = props

        const { isOpen, onOpen, onClose } = useDisclosure()

        const [Availability, setAvailability] = useState(true)
        const [FirstName, setFirstName] = useState('')
        const [Surname, setSurname] = useState('')
        const [Email, setEmail] = useState('')
        const [Phone, setPhone] = useState('')
        const [Imdb, setImdb] = useState('')
        const [Spotlight, setSpotlight] = useState('')
        const [FurtherInfo, setFurtherInfo] = useState('')

        const [FormSuccess, setFormSuccess] = useState(0)
        const [UploadSuccess, setUploadSuccess] = useState(0)
        const [uploadProg, setUploadProg] = useState(0)
        const [selectedFiles, setSelectedFiles] = useState()
        const [filesAttached, setFilesAttached] = useState(0)

        const { handleSubmit, errors, register, formState, control } = useForm()

        function validateFirstname(value) {
                let error
                if (!value) {
                        error = 'First Name is required. '
                }
                return error || true
        }

        function validateSurname(value) {
                let error
                if (!value) {
                        error = 'Last Name is required. '
                }
                return error || true
        }

        function validateAvailability(value) {
                let error
                if (!value) {
                        error = 'Please Confirm Your Availability. '
                }
                return error || true
        }

        function validateEmail(inputText) {
                let error
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(inputText)) {
                        error = 'Email Address Not Recognized. '
                }
                return error || true
        }

        async function submitHandler() {
                const scriptURL =
                        'https://script.google.com/macros/s/AKfycbzmz-RcsmTerQN_jC_bIri0TanNuy8wt4-n3r8zAJQPAUxAP1E/exec'
                const form = document.forms.emailform
                try {
                        const response = await fetch(scriptURL, { method: 'POST', body: new FormData(form) })
                        console.log('Form Success!', response)
                        setFormSuccess(1)
                } catch (error) {
                        console.error('Form Error!', error.message)
                        setFormSuccess(2)
                }
        }

        async function spotlightPdf() {
                const lambdaPdfFunction = 'https://37ag4pb88c.execute-api.us-east-1.amazonaws.com/dev/pdf'
                try {
                        let spotlightPost = ''
                        spotlightPost = await fetch(
                                `${lambdaPdfFunction}?address=https://spotlight.com/interactive/cv/${Spotlight}&name=${project}/${Surname}${FirstName.charAt(
                                        0
                                )}/${Surname}${FirstName.charAt(0)}-spotlight`,
                                { method: 'POST' }
                        )
                        console.log('Spotlight Pdf Success!', spotlightPost)
                } catch (error) {
                        console.error('Spotlight Pdf Error!', error.message)
                }
        }

        async function formPdf() {
                // put this in external template component looping over
                const myElement = `<div><h1>Project:${project}</h1><h2>${FirstName} ${Surname} </h2><div>More stuff in here</div></div>`

                const lambdaPdfFunction = 'https://37ag4pb88c.execute-api.us-east-1.amazonaws.com/dev/html-pdf'
                try {
                        let formPost = ''
                        formPost = await fetch(
                                `${lambdaPdfFunction}?html=${myElement}&name=${project}/${Surname}${FirstName.charAt(
                                        0
                                )}/${Surname}${FirstName.charAt(0)}-application`,
                                { method: 'POST' }
                        )
                        console.log('Form Pdf Success!', formPost)
                } catch (error) {
                        console.error('Form Pdf Error!', error.message)
                }
        }

        async function onSubmit() {
                if (selectedFiles) {
                        await childRef.current.upload()
                        // submitHandler(),
                        console.log('form1b - yo!')
                        await formPdf()
                        await spotlightPdf()
                        onOpen()
                        console.log(filesAttached)
                } else {
                        setFilesAttached(1)
                        console.log(filesAttached)
                }
        }

        function onCloseClear() {
                setFirstName('')
                setSurname('')
                setEmail('')
                setPhone('')
                setImdb('')
                setSpotlight('')
                setSelectedFiles('')
                setFurtherInfo('')
                onClose()
        }

        return (
                <>
                        <form id="emailform" onSubmit={executeCaptcha} className="myform">
                                <FormControl
                                        isInvalid={errors.Surname || errors.Firstname || errors.Email || errors.Phone}
                                        className="formcontrolx"
                                >
                                        <Input
                                                name="Firstname"
                                                placeholder="First Name"
                                                isRequired
                                                ref={register({ validate: validateFirstname })}
                                                onChange={e => setFirstName(e.target.value)}
                                                value={FirstName}
                                                m="2"
                                                width={{ sm: '100%', xl: '30%' }}
                                                flexGrow="1"
                                                borderColor="purple.100"
                                        />
                                        <Input
                                                name="Surname"
                                                placeholder="Last Name"
                                                isRequired
                                                ref={register({ validate: validateSurname })}
                                                onChange={e => setSurname(e.target.value)}
                                                value={Surname}
                                                m="2"
                                                width={{ sm: '100%', xl: '30%' }}
                                                flexGrow="1"
                                                borderColor="purple.100"
                                        />
                                        <InputGroup mt={3}>
                                                <InputLeftAddon
                                                        ml={2}
                                                        pointerEvents="none"
                                                        borderColor="purple.100"
                                                        children=<EmailIcon color="gray.800" />
                                                />
                                                <Input
                                                        name="Email"
                                                        placeholder="Email"
                                                        isRequired
                                                        ref={register({ validate: validateEmail })}
                                                        onChange={e => setEmail(e.target.value)}
                                                        value={Email}
                                                        width={{ sm: '100%', xl: '50%' }}
                                                        flexGrow="1"
                                                        borderColor="purple.100"
                                                />
                                        </InputGroup>
                                        <InputGroup mt={3}>
                                                <InputLeftAddon
                                                        ml={2}
                                                        pointerEvents="none"
                                                        borderColor="purple.100"
                                                        children=<PhoneIcon color="gray.800" />
                                                />
                                                <Input
                                                        name="Phone"
                                                        placeholder="Phone"
                                                        type="tel"
                                                        pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
                                                        ref={register()}
                                                        onChange={e => setPhone(e.target.value)}
                                                        value={Phone}
                                                        width={{ sm: '100%', xl: '30%' }}
                                                        flexGrow="1"
                                                        borderColor="purple.100"
                                                />
                                        </InputGroup>
                                        <FormLabel htmlFor="Spotlight" ml={2} mt={4} mb={0}>
                                                Spotlight Link
                                        </FormLabel>
                                        <InputGroup>
                                                <InputLeftAddon
                                                        borderColor="purple.100"
                                                        children="spotlight.com/profile/"
                                                        ml={2}
                                                />
                                                <Tooltip label="Format: 1234-5678-1234" aria-label="Spotlight Format">
                                                        <Input
                                                                name="Spotlight"
                                                                type="text"
                                                                placeholder="Spotlight ID"
                                                                ref={register()}
                                                                onChange={e => setSpotlight(e.target.value)}
                                                                value={Spotlight} // flexGrow="1"
                                                                borderColor="purple.100"
                                                                borderLeftRadius="0"
                                                                mr={2}
                                                        />
                                                </Tooltip>
                                        </InputGroup>
                                        <FormLabel htmlFor="Imdb" ml={2} mt={4} mb={0}>
                                                IMDB Link
                                        </FormLabel>
                                        <InputGroup>
                                                <InputLeftAddon
                                                        borderColor="purple.100"
                                                        children="imdb.com/name/"
                                                        ml={2}
                                                />
                                                <Tooltip label="Format: nm12345678" aria-label="IMDB Format">
                                                        <Input
                                                                name="Imdb"
                                                                placeholder="e.g.nm1234567/"
                                                                ref={register()}
                                                                onChange={e => setImdb(e.target.value)}
                                                                value={Imdb}
                                                                // flexGrow="1"
                                                                borderColor="purple.100"
                                                                borderLeftRadius="0"
                                                                mr={2}
                                                        />
                                                </Tooltip>
                                        </InputGroup>
                                        <FormLabel htmlFor="OtherInfo" ml={2} mt={4} mb={0}>
                                                Further Information
                                        </FormLabel>{' '}
                                        <Textarea
                                                name="OtherInfo"
                                                placeholder="Any further information"
                                                m="2"
                                                ref={register()}
                                                borderColor="purple.100"
                                                onChange={e => setFurtherInfo(e.target.value)}
                                                value={FurtherInfo}
                                        />
                                        <FormErrorMessage>
                                                {errors.Firstname && errors.Firstname.message}
                                                {errors.Surname && errors.Surname.message}
                                                {errors.Email && errors.Email.message}
                                        </FormErrorMessage>
                                </FormControl>
                        </form>

                        <Flex flexDirection="column" justifyContent="left" className="bottom-block">
                                <Upload
                                        ref={childRef}
                                        surname={Surname}
                                        firstname={FirstName}
                                        uploadprog={uploadProg}
                                        setuploadprog={setUploadProg}
                                        uploadsuccess={UploadSuccess}
                                        setuploadsuccess={setUploadSuccess}
                                        setselectedfiles={setSelectedFiles}
                                        selectedfiles={selectedFiles}
                                        setfilesattached={setFilesAttached}
                                        project={project}
                                />
                                {filesAttached ? (
                                        <div aria-live="polite" style={{ color: 'red' }}>
                                                Please Attach your Self Tape
                                        </div>
                                ) : (
                                        ''
                                )}

                                <Button
                                        width="200px"
                                        onClick={handleSubmit(onSubmit)}
                                        mt={4}
                                        isLoading={formState.isSubmitting}
                                        borderColor="#811672"
                                        borderWidth="1px"
                                >
                                        {' '}
                                        Submit{' '}
                                </Button>
                        </Flex>
                        <SubmitModal
                                isopen={isOpen}
                                onopen={onOpen}
                                onclose={onCloseClear}
                                firstname={FirstName}
                                surname={Surname}
                                uploadsuccess={UploadSuccess}
                                formsuccess={FormSuccess}
                        />

                        <Recaptcha
                                ref={e => (recaptchaInstance = e)}
                                sitekey="6LdEiOEZAAAAALkNjecKwayqjmPyqcrgMW-mfNvk"
                                boxSize="invisible"
                                verifyCallback={handleSubmit(onSubmit)}
                        />
                </>
        )
}
